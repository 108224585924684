import React from "react";
import "./homepage.css";
import { NavLink } from "react-router-dom";
import RszMan1 from "../../images/painter.jpg";
import ColorChart from "../../images/color-chart.jpg";
import Paintbrush from "../../images/paintbrush.jpg";
import Comma1 from "../../images/comma1.png";
import Comma2 from "../../images/comma2.png"

const Homepage = () => {
  return (
    <>
      <div className="header-image">
        <h1>Specialist painting and decorating services with over 40 years experience</h1>
        <NavLink to="/contact">
        <div className="contact-button">CONTACT US</div>
        </NavLink>
      </div>
      <div className="homeContainer">
        <div className="about">
          <div className="aboutImage">
            <img src={RszMan1} alt="about" className="homepage-image" />
          </div>

          <div className="about-us1">
            <h1>About us</h1>
            <p>Roy Wakeford started his own decorating business over 35 years ago and now has over 40 years of experience.</p>
            <NavLink to="/about">
              <div className="about-button">LEARN MORE</div>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="homepage-banner-top">
        <div className="comma"><img src={Comma1} alt="comma" style={{width: '150px', height: '150px'}}/></div>
      <div className=".homepage-banner-text">
        <h2>What our customers say</h2>
        <p>What a duo, we couldn’t recommend Roy and Jay more! Extremely lovely, kind and trustworthy individuals who have done an incredible job with painting nearly every room in our house. We will definitely be using these guys again in the future. 100% recommend.</p>
        <div className="reviewer">- Mrs Dancy</div>
        </div>
        <div className="comma"><div className="comma"><img src={Comma2} alt="comma" style={{width: '150px', height: '150px'}}/></div></div>
      </div>

      <div className="homeContainer">
        <div className="recent-work">
          <div className="aboutImage2">
            <img src={ColorChart} alt="about" className="homepage-image" />
          </div>

          <div className="about-us2">
            <h1>What we offer</h1>
            <p>We provide both interior and exterior services, along with a host of luxurious finishes.</p>
            <div className="content-end">
            <NavLink to="/about">
              <div className="about-button">LEARN MORE</div>
            </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="banner">
        <h2>Contact us today for a FREE no obligation quote</h2>
        <NavLink to="/contact">
          <div className="about-button">CONTACT US</div>
        </NavLink>
      </div>

      <div className="homeContainer2">
        <div className="about">
          <div className="aboutImage">
            <img src={Paintbrush} alt="about" className="homepage-image" />
          </div>

          <div className="about-us1">
            <h1>Recent work</h1>
            <p>Browse our gallery full of completed projects and read our Checkatrade reviews.</p>
            <NavLink to="/Gallery">
              <div className="about-button">SEE MORE</div>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
