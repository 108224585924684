import React from "react";
import "./gallery.css";
import CheckatradeLogo1 from "../../images/checkatrade-com.png";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import SliderData from "../../components/imageSlider/SliderData";

const Gallery = () => {
  return (
    <div className="gallery-page-container">
      <div className="top-content-container">
        <div className="top-content">
          <div className="top-content-wrapper">
            <h1>Recent work</h1>
            <p>We take pride in every project, working closely with our customers from start to finish to ensure we achieve exactly what they are looking for.</p>
            <p>
              Don’t just take our word for it, take a look through our gallery and read some of our reviews from previous customers on our Checkatrade profile, by clicking the button <span className="click-instruction">to the right.</span>
              <span className="click-instruction2">below.</span>
            </p>
          </div>
        </div>

        <div className="check-trade-button">
          <a href="https://www.checkatrade.com/trades/RoyWakefordInteriorandExterior/">
            <img className="check-trade-image" src={CheckatradeLogo1} alt="after" />
            <div className="reviews-button-container">
              <div className="reviews-button">READ OUR REVIEWS</div>
            </div>
          </a>
        </div>
      </div>


    <ImageSlider slides={SliderData}/>
    </div>
  );
};

export default Gallery;
