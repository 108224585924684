import React from "react";
import { NavLink } from "react-router-dom";
import "./services.css";
import Wasps from "../../images/wasps.jpg";
import ServicesPic1 from "../../images/services-img.jpg";

const Services = () => {
  return (
    <>
      <div className="services-container">
        <div className="offer">
          <div className="offerImage">
            <img src={ServicesPic1} alt="paintbrush and color pallette" style={{ width: "400px", height: "400px" }} />
          </div>

          <div className="service-details">
            <h1>Our services</h1>
            <p>At Roy Wakeford Decorators, we have been delivering a wide range of painting and decorating services for over 35 years.</p>
            <p>Our expert team will help you turn your vision into a reality with a host of luxurious finishes.</p>

            <div className="services-1-container">
              <div className="services-1">
                <h3>We can provide:</h3>
                <ul>
                  <li>Interior and exterior services</li>
                  <li>Commercial and domestic services</li>
                  <li>Complete surface preparation</li>
                  <li>All types of painting</li>
                  <li>Wallpapering, wall lining and other wall coverings</li>
                </ul>
              </div>

              <div className="services-1">
                <h3>We pride ourselves on:</h3>
                <ul>
                  <li>Good quality products</li>
                  <li>Competitive rates</li>
                  <li>Honest advice</li>
                  <li>No hidden costs</li>
                  <li>Clean and tidy service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-banner">
        <h2>Contact us today for a FREE no obligation quote</h2>
        <NavLink to="/contact">
          <div className="about-button">GET IN TOUCH</div>
        </NavLink>
      </div>

      <div className="services-container">
        <div className="offer-bottom">
          <div className="offerImage">
            <img src={Wasps} alt="about" style={{ width: "400px", height: "400px" }} />
            <p className="small-text">*In the unlikely event that our treatment does not eradicate the problem, we will come out again and resolve the situation at no extra cost. </p>
            <p className="small-text">Please note, we do not treat colonies of honey bees. Due to the decline in native bees, you should contact a bee keeper and they can relocate the nest for you.</p>
          </div>

          <div className="services-2">
            <h1>Wasp nest removal</h1>
            <p>If you have a wasp or hornet nest on your property, please do not try to deal with it yourself. Disturbed wasps are highly defensive and can get extremely angry. Wasp stings can cause anaphylactic shock in some people and in extreme cases, this can be life threatening.</p>
            <p>Most importantly, if you come across a wasp or hornet nest, never:</p>
            <ul>
              <li>Set fire to it</li>
              <li>Try to flood it</li>
              <li>Attempt to block the entrance</li>
            </ul>
            <p>We have 10 years experience in pest control and are experts in wasp nest removals. We know how to deal with nests and have specialist tools that enable us to reach awkward locations.</p>
            <p>Contact us today to arrange a visit to your property at a time that suits you, where we will offer a fixed-price and guaranteed service.*</p>
            <NavLink to="/contact">
              <div className="services-about-button-container">
                <div className="services-contact-button">CONTACT US</div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="border" />
    </>
  );
};

export default Services;
