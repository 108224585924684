import React from "react";
import "./header.css";
import { NavLink } from "react-router-dom";
import royLogo from "../../images/royLogo.png";

const Header = () => {
  return (
    <div className="container">
      <div className="logo">
        <NavLink to="/">
          {" "}
          <img src={royLogo} alt="Roy Logo" />{" "}
        </NavLink>
      </div>
      <div className="links">
        <NavLink to="/">
          <div className="header-button">HOME</div>
        </NavLink>
        <NavLink to="/about">
          {" "}
          <div className="header-button">ABOUT</div>
        </NavLink>
        <NavLink to="/Services">
          <div className="header-button">SERVICES</div>
        </NavLink>
        <NavLink to="/Gallery">
          <div className="header-button">GALLERY</div>
        </NavLink>
        <NavLink to="/contact">
          {" "}
          <div className="header-button">CONTACT</div>
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
