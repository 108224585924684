import React from "react";
import "./contact.css";
import Phone from "../../images/phone.png";
import Mobile from "../../images/mobile.png";
import Email from "../../images/email.png";
import PaintRoller from "../../images/paint-roller.jpg";
import Roller from "../../images/paint-roller-2.jpeg";
import Comma1 from "../../images/comma1.png";
import Comma2 from "../../images/comma2.png";

const Contact = () => {
  return (
    <>
      <div className="contact-container">
        <div className="contact-content-top">
          <div className="contactImage">
            <img src={PaintRoller} alt="about" className="roller-brush-image" />
          </div>

          <div className="contact-us1">
            <h1>Contact us</h1>
            <p>Based in Paddock Wood, we cover all areas of Kent, including Tonbridge, Tunbridge Wells and Sevenoaks.</p>
            <p>Get in touch today to arrange a FREE no obligation quote or to book a wasp nest removal.</p>

            <div className="contact-details-container">
              <div className="contact-details">
                <div className="details-half">
                  <div className="detail">
                    <img src={Mobile} alt="phone" className="contact-icons" />
                    <div className="details-right">
                      <h2 className="less-margin-title">Mobile</h2>
                      <p>07889 372890</p>
                    </div>
                  </div>
                  <div className="detail">
                    <img src={Phone} alt="phone" className="contact-icons" />
                    <div className="details-right">
                      <h2 className="less-margin-title">Phone</h2>
                      <p>01892 832998</p>
                    </div>
                  </div>
                </div>

                <div className="details-half">
                  <div className="detail">
                    <img src={Email} alt="phone" className="contact-icons" />
                    <div className="details-right">
                      <h2 className="less-margin-title">Email</h2>
                      <a href="mailto:rwdecorators@hotmail.co.uk">
                        <p>rwdecorators@hotmail.co.uk</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contactImage2">
                <img src={Roller} alt="about" className="roller-brush-image" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="homepage-banner-top-contact">
        <div className="comma">
          <img src={Comma1} alt="comma" style={{ width: "150px", height: "150px" }} />
        </div>
        <div className=".homepage-banner-text">
          <h2>What our customers say</h2>
          <p>Roy and Jay are a great team. Polite, friendly and very professional. They always do a great job and leave things clean and tidy. I have recommended them to all my neighbours and friends. Will definitely use them again for future paint work.</p>
          <div className="reviewer">- Mrs Cross</div>
        </div>
        <div className="comma">
          <div className="comma">
            <img src={Comma2} alt="comma" style={{ width: "150px", height: "150px" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
