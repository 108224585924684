import React from "react";
import "./about.css";
import { NavLink } from "react-router-dom";
import Ladder1 from "../../images/ladder.jpg";
import Comma1 from "../../images/comma1.png";
import Comma2 from "../../images/comma2.png";

const About = () => {
  return (
    <>
      <div className="about-container">
        <div className="about-page">
          <div className="about-page-image">
            <img src="https://res.cloudinary.com/dlxqugu1z/image/upload/v1635192766/ladder1_l5qrbg.jpg" alt="about" className="about-image" />
          </div>

          <div className="about-us-content">
            <h1>About us</h1>
            <p>Roy Wakeford started his own decorating business over 35 years ago and now has over 40 years of experience. He has an advanced City &amp; Guilds qualification in decorating from Tonbridge School and has extensive experience of the trade, including a year working for Mohamed Al-Fayed.</p>
            <p>We do not subcontract work, all of our jobs are completed by Roy and his team of highly skilled painters and decorators. We cover all areas of Kent, including Tonbridge, Tunbridge Wells and Sevenoaks and are based in the Paddock Wood area.</p>
            <p>Providing a reliable, friendly and professional service, we offer a full range of interior and exterior painting and decorating services, so why not contact us for a free no-obligation quote?</p>
            <p>
              <b>Why not contact us for a free no-obligation quote?</b>
            </p>
            <div className="about-page-button-container">
              <img src={Ladder1} alt="about" className="about-image2" />
              <NavLink to="/contact">
                <div className="about-page-button">CONTACT US</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="homepage-banner-top">
        <div className="comma">
          <img src={Comma1} alt="comma" style={{ width: "150px", height: "150px" }} />
        </div>
        <div className=".homepage-banner-text">
          <h2>What our customers say</h2>
          <p>Roy managed to slot us in at relatively short notice, knowing we were expecting a baby shortly after the completion date. Roy and Jay were professional, tidy and friendly and kept to agreed timescales, showing up promptly everyday. We were very pleased with the finished job. Many thanks!</p>
          <div className="reviewer">- Mr Bailey</div>
        </div>
        <div className="comma">
          <div className="comma">
            <img src={Comma2} alt="comma" style={{ width: "150px", height: "150px" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
