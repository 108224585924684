import React from 'react';
import Gallery1 from "../../images/gallery-1.png";
import Gallery2 from "../../images/gallery-2.png";
import Gallery3 from "../../images/gallery-3.png";
import Gallery4 from "../../images/gallery-4.png";
import Gallery5 from "../../images/gallery-5.jpg";
import Gallery6 from "../../images/gallery-6.jpg";

const SliderData = [
  {
    image: Gallery1
  },
  {
    image: Gallery2
  },
  {
    image: Gallery3
  },
  {
    image: Gallery4
  },
  {
    image: Gallery5
  },
  {
    image: Gallery6
  }
]

export default SliderData;
