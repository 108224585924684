import React from "react";
import "./footer.css";
import CheckatradeLogo3 from "../../images/checkatrade-no-strapline.png";
import Phone from "../../images/phone.png";
import Mobile from "../../images/mobile.png";
import Email from "../../images/email.png";
import CityAndGuilds1 from "../../images/city-and-guilds.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-top">
        <div className="top-footer-link">
          <p className="no-margin-bottom">Contact us</p>
          <div className="footer-contact-details">
            <div className="footer-contact-detail">
              <img src={Mobile} alt="mobile" className="footer-contact-icons more-margin-top" />
              <p className="no-margin-bottom">07889 372890</p>
            </div>

            <div className="footer-contact-detail">
              <img src={Phone} alt="phone" className="footer-contact-icons more-margin-top" />
              <p className="no-margin-bottom">01892 832998</p>
            </div>

            <div className="footer-contact-detail">
              <img src={Email} alt="email" className="footer-contact-icons" />
              <a href="mailto:rwdecorators@hotmail.co.uk">
                <p className="no-margin-bottom">rwdecorators@hotmail.co.uk</p>
              </a>
            </div>
          </div>
        </div>

        <div className="top-footer-link">
          <p>Accredited by</p>
          <div className="footer-contact-details">
            <img src={CityAndGuilds1} alt="phone" className="city-guilds-logo" />
          </div>
        </div>

        <div className="top-footer-link">
          <p>Leave us a review</p>
          <a href="https://www.checkatrade.com/trades/RoyWakefordInteriorandExterior/" target="blank">
            <div className="footer-contact-details">
              <img src={CheckatradeLogo3} alt="phone" style={{ height: "25px", width: "160px" }} />
            </div>
          </a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; The content on this website is owned by us. Do not copy any content without consent.</p>
      </div>
    </div>
  );
};

export default Footer;
